import React, { FC } from 'react';
import { Table as MuiTable, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { cyan, grey } from '@mui/material/colors';

interface Props {
  data: {
    serviceName: string;
    servicePrices: {
      name: string;
      price: string;
    }[];
  };
  searchTerm: string;
}

const Table: FC<Props> = ({ data, searchTerm }) => {
  // eslint-disable-next-line
  const matchedData =
    searchTerm === ''
      ? data.servicePrices
      : data.servicePrices.filter((servicePrice) => servicePrice.name.toLowerCase().includes(searchTerm.toLowerCase()));

  if (!matchedData.length) {
    return null;
  }

  return (
    <TableContainer
      sx={{
        minWidth: 300,
        maxWidth: 1200,
        border: '1px solid',
        background: grey[200],
        borderColor: grey[300],
        boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        '&:hover': { background: cyan[400], transition: 'background ease-in 0.25s' },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          padding: 1,
          paddingLeft: 2,
          borderBottom: '1px solid',
          borderColor: grey[300],
        }}
      >
        {data.serviceName}
      </Typography>
      <MuiTable aria-label="simple table" sx={{ background: 'white' }}>
        <TableBody>
          {matchedData.map((row) => {
            const price = Number.isNaN(Number.parseInt(row.price, 10)) ? row.price : `${row.price} RSD`;
            return (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                  {price}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
