import styled from 'styled-components';

interface Props {
  border?: string;
  padding?: string;
  margin?: string;
}

const Container = styled.div`
  min-height: 100vh;
  border: ${(props: Props) => (props.border ? props.border : 'none')};
  padding: ${(props: Props) => (props.padding ? props.padding : '60px 0 30px')};
  margin: ${(props: Props) => (props.margin ? props.margin : '0')};
`;

export default Container;
