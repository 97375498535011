import emailjs from '@emailjs/browser';
import ContactData from '../models/contactData';

const SERVICE_ID = 'service_sc2v8iz';
const TEMPLATE_ID = 'template_m016xwv';

const sendEmail = async (data: ContactData): Promise<void> => {
  await emailjs.send(SERVICE_ID, TEMPLATE_ID, { ...data });
};

export default sendEmail;
