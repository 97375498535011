import React, { ReactElement } from 'react';
import Container from 'components/common/Container';
import { useParams, Navigate } from 'react-router-dom';
import { services } from 'data/services.json';
import Breadcrumb from 'components/common/Breadcrumb';
import { Stack, Typography } from '@mui/material';
import ServiceDetailText from 'components/common/ServiceDetailText';

const ServiceDetail = (): ReactElement => {
  const { id } = useParams();
  const service = services.find(({ id: serviceId }) => serviceId.toString() === id);

  if (!service) {
    return <Navigate replace to="/" />;
  }

  const breadCrumbs = [
    {
      name: 'Usluge',
      path: '/usluge',
    },
    {
      name: service.serviceName,
    },
  ];

  return (
    <Container padding="65px 0 30px">
      <Breadcrumb crumbs={breadCrumbs} />
      <Stack spacing={2} alignItems="center" sx={{ px: 3 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
          {service.serviceName}
        </Typography>
        <ServiceDetailText />
      </Stack>
    </Container>
  );
};

export default ServiceDetail;
