import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import emailjs from '@emailjs/browser';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'antd/dist/antd.css';
import preventGoogleMapFontOverride from './preventGoogleMapFontOverride.js';

preventGoogleMapFontOverride();
const emailJSPublicKey = 'kANr0aR0JwKos90s4';
emailjs.init(emailJSPublicKey);

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
