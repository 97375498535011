import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Alert, Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { grey, cyan } from '@mui/material/colors';
import { EmailOutlined, LocationOnOutlined, PhoneOutlined } from '@mui/icons-material';
import ContactData from 'models/contactData';
import siteInfo from '../../data/site-info.json';
import sendEmail from '../../service/email';

const initialData = { name: '', phone: '', email: '', details: '' };

const ContactUsForm = (): ReactElement => {
  const { address, email, phone } = siteInfo;
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ContactData>(initialData);

  const showSuccessAndResetForm = () => {
    setSuccess(true);
    setData(initialData);

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    sendEmail(data).then(showSuccessAndResetForm);
  };

  return (
    <Box
      sx={{
        background: grey[100],
        p: { xs: 3, sm: 4 },
        boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      }}
    >
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
        Kontaktirajte nas:
      </Typography>
      <Stack spacing={2} sx={{ mb: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: { sx: 18, sm: 20 } }}>
          <LocationOnOutlined />
          <Box>{address}</Box>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: { sx: 18, sm: 20 } }}>
          <PhoneOutlined />
          <Box>{phone}</Box>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: { sx: 18, sm: 20 } }}>
          <EmailOutlined />
          <Box>{email}</Box>
        </Stack>
      </Stack>
      <Divider />
      <Typography variant="h5" fontWeight="bold" sx={{ my: 3 }}>
        Posaljite nam poruku:
      </Typography>
      <Stack spacing={3}>
        <TextField
          required
          name="name"
          id="name"
          label="Ime i prezime"
          sx={{ background: 'white', width: { xs: '100%', sm: '300px' } }}
          value={data.name}
          onChange={handleChange}
        />
        <TextField
          required
          name="phone"
          id="phone-number"
          label="Broj telefona"
          sx={{ background: 'white', width: { xs: '100%', sm: '300px' } }}
          value={data.phone}
          onChange={handleChange}
        />
        <TextField
          required
          name="email"
          id="email"
          label="Email"
          sx={{ background: 'white', width: { xs: '100%', sm: '300px' } }}
          value={data.email}
          onChange={handleChange}
        />
        <TextField
          required
          name="details"
          label="Poruka"
          placeholder="Posaljite nam poruku, a mi cemo vas kontaktirati sto pre..."
          multiline
          rows={3}
          maxRows={4}
          sx={{ background: 'white' }}
          value={data.details}
          onChange={handleChange}
        />
        {success && <Alert severity="success">Vasa poruka je uspesno poslata!</Alert>}
      </Stack>
      <Box sx={{ textAlign: 'center', pt: 3 }}>
        <Button
          variant="contained"
          size="large"
          sx={{ background: cyan[500], fontWeight: 'bold', px: 5, '&:hover': { background: cyan[600] } }}
          onClick={handleSubmit}
        >
          Posalji
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
