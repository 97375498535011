import React, { FC, ReactElement } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Container from 'components/common/Container';
import Package from 'components/common/Package';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import packages from '../../data/packages.json';

const fadeInAnimation = keyframes`${fadeIn}`;

const SliderContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const SliderImage = styled.img`
  object-fit: cover;
  width: 100vw;
  height: calc(100vh - 60px);
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  position: absolute;
  font-weight: bold;
  top: 25%;
  margin: 0 5vw 0;
  padding: 40px 30px;
  background-color: rgba(255, 255, 255, 0.5);
  animation: 500ms ${fadeInAnimation};
`;

interface Props {
  images: string[];
}

const Landing: FC<Props> = ({ images }): ReactElement => {
  const image1 = window.screen.width > 500 ? images[0] : images[1];

  return (
    <Container>
      <SliderContainer>
        <Slider
          fade
          autoplay
          infinite
          arrows={false}
          speed={1000}
          autoplaySpeed={4000}
          slidesToShow={1}
          slidesToScroll={1}
        >
          <SliderImage src={image1} />
          <SliderImage src={images[2]} />
          <SliderImage src={images[3]} />
          <SliderImage src={images[4]} />
        </Slider>
        <TitleContainer>
          <Typography fontWeight="bold" sx={{ mb: 1, fontSize: { xs: 24, sm: 36, md: 48 } }}>
            Kale Medika - Vaše zdravlje, naša misija!
          </Typography>
          <Typography fontWeight="bold" sx={{ fontSize: { xs: 18, sm: 24, md: 30 } }}>
            Budite sebi na prvom mestu...
          </Typography>
        </TitleContainer>
      </SliderContainer>
      <Box
        sx={{
          background: grey[100],
          px: { xs: 2, sm: 6, md: 12 },
          py: 6,
          m: '15px 5vw',
          boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          textAlign: 'center',
        }}
      >
        <Typography fontWeight="bold" sx={{ mb: 3, fontSize: { xs: 24, sm: 30, md: 36 } }}>
          Dobrodošli!
        </Typography>
        <Typography sx={{ mb: 3, fontSize: { xs: 16, sm: 22, md: 28 } }}>
          Kale Medika je internistička ordinacija u kojoj se obavlja specijalističko - konsultativna delatnost i pružaju
          preventivne, dijagnostičke i terapijske zdravstvene usluge iz oblasti pulmologije, kardiologije,
          endokrinologije, neurologije, hematologije i gastroenterohepatologije. Nudimo Vam brojne ultrazvučne
          dijagnostičke preglede i raznovrsne pakete infuzionih terapija. Sa ponosom ističemo da u našoj ordinaciji
          možete dobiti najširi spektar tretmana akutnih i hroničnih bolnih stanja po uzoru na najveće svetske centre iz
          oblasti medicine bola.
        </Typography>
        <Typography fontWeight="bold" sx={{ fontSize: { xs: 18, sm: 24, md: 30 } }}>
          U prijatnom ambijentu, o Vašem zdravlju, brine medicinski tim sastavljen od vrhunskih stručnjaka!
        </Typography>
      </Box>
      <Box
        sx={{
          mx: '5vw',
          my: 6,
          position: 'relative',
          height: { xs: '140vh', sm: '110vh', md: '90vh', lg: '85vh' },
        }}
      >
        <Box
          sx={{
            width: { md: '90vw', lg: '60vw' },
            height: { xs: '75vh', sm: '65vh' },
            position: 'absolute',
            top: 0,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          }}
        >
          <Image src={images[5]} />
        </Box>
        <Box
          sx={{
            background: grey[100],
            px: { xs: 2, sm: 3, md: 4 },
            py: { xs: 2, sm: 3, md: 4 },
            boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            width: { md: '80vw', lg: '55vw' },
            maxWidth: '80vw',
            position: 'absolute',
            bottom: 0,
            right: { xs: '5vw', sm: '5vw', md: '5vw', lg: 0 },
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            {packages.map(({ name, price, services, theme }) => (
              <Grid item xs={12} sm={12} md={6}>
                <Package name={name} price={price} services={services} darkTheme={theme === 'dark'} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Landing;
