import React, { FC } from 'react';
import { blueGrey, grey } from '@mui/material/colors';
import {
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  LinkedIn,
  LocationOnOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import siteInfo from '../../data/site-info.json';

const Footer: FC = () => {
  const { address, email, phone, officeHours, socialNetworks, rightsText } = siteInfo;
  const { workdays, weekend } = officeHours;
  const { facebook, instagram, linkedin } = socialNetworks;

  return (
    <>
      <Stack
        justifyContent="space-around"
        direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
        sx={{ py: { xs: 2, sm: 2, md: 5, lg: 5 }, background: grey[200] }}
      >
        <Stack
          spacing={1}
          justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' }}
          alignItems={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' }}
          direction={{ xs: 'row', sm: 'row', md: 'column', lg: 'column' }}
          sx={{ mb: { xs: 2, sm: 2 } }}
        >
          <Stack
            direction="row"
            spacing={1}
            onClick={() => {
              window.location.href = facebook.link;
            }}
            sx={{ cursor: 'pointer' }}
          >
            <FacebookOutlined sx={{ fontSize: { xs: '32px', sm: '32px', md: '24px', lg: '24px' } }} />
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>Facebook</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            onClick={() => {
              window.location.href = instagram.link;
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Instagram sx={{ fontSize: { xs: '32px', sm: '32px', md: '24px', lg: '24px' } }} />
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>Instagram</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            onClick={() => {
              window.location.href = linkedin.link;
            }}
            sx={{ cursor: 'pointer' }}
          >
            <LinkedIn sx={{ fontSize: { xs: '32px', sm: '32px', md: '24px', lg: '24px' } }} />
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>Linkedin</Box>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          alignItems={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' }}
          sx={{ mb: { xs: 2, sm: 2 } }}
        >
          <Stack direction="row" spacing={1}>
            <LocationOnOutlined />
            <Box>{address}</Box>
          </Stack>
          <Stack direction="row" spacing={1}>
            <PhoneOutlined />
            <Box>{phone}</Box>
          </Stack>
          <Stack direction="row" spacing={1}>
            <EmailOutlined />
            <Box>{email}</Box>
          </Stack>
        </Stack>
        <Stack spacing={1} alignItems={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'inherit' }}>
            Radno vreme:
          </Typography>
          <Box>{workdays}</Box>
          <Box>{weekend}</Box>
        </Stack>
      </Stack>
      <Stack justifyContent="space-around" direction="row" sx={{ p: '2px', color: 'white', background: blueGrey[900] }}>
        <Typography variant="caption" display="block">
          {rightsText}
        </Typography>
      </Stack>
    </>
  );
};

export default Footer;
