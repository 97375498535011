import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import React, { FC, useEffect, useState } from 'react';
import logo from '../../assets/images/kale_medika_logo.png';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const Container = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: ${(props) => (props.show ? '0' : '100vh')};
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out;
`;

const LogoContainer = styled.div`
  background-color: #4dd0e1;
  animation: 800ms ${fadeInUpAnimation};
`;

const Logo = styled.img`
  object-fit: cover;
  width: 250px;
  height: 100px;
`;

interface Props {
  loading: boolean;
}

const Welcome: FC<Props> = ({ loading }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => setShow(loading), 600);
  }, [loading]);

  return (
    <Container show={show}>
      <LogoContainer>
        <Logo src={logo} alt="kale_medika" />
      </LogoContainer>
    </Container>
  );
};

export default Welcome;
