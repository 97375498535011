import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useImagePreloader from 'hooks/useImagePreloader';
import Welcome from 'components/common/Welcome';
import Landing from 'pages/Landing/Landing';
import Footer from 'components/common/Footer';
import Prices from 'pages/Prices/Prices';
import RoutingProvider from 'context/RoutingContext';
import Navbar from 'components/common/Navbar';
import Services from 'pages/Services/Services';
import ServiceDetail from 'pages/ServiceDetail/ServiceDetail';
import ScrollToTop from 'utils/ScrollToTop';
import Contact from './pages/Contact/Contact';

const App = (): ReactElement => {
  const { landingImages, serviceImages, imagesPreloaded } = useImagePreloader();
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutingProvider>
        <Welcome loading={!imagesPreloaded} />
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing images={landingImages} />} />
          {/* <Route path="/o-nama" element={<AboutUs />} /> */}
          {/* <Route path="/nas-tim" element={<OurTeam />} />
          <Route path="/nas-tim/:id" element={<Doctor />} /> */}
          <Route path="/usluge" element={<Services images={serviceImages} />} />
          <Route path="/usluge/:id" element={<ServiceDetail />} />
          <Route path="/cenovnik" element={<Prices />} />
          {/* <Route path="/galerija" element={<Gallery />} /> */}
          <Route path="/kontakt" element={<Contact />} />
        </Routes>
        <Footer />
      </RoutingProvider>
    </BrowserRouter>
  );
};

export default App;
