import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutingContext } from '../context/RoutingContext';

const useRouteNavigate = (): ((route: string) => void) => {
  const { setRoute } = useContext(RoutingContext);
  const navigate = useNavigate();

  return (route: string) => {
    setRoute(route);
    navigate(route);
  };
};

export default useRouteNavigate;
