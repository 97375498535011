import React, { ReactElement } from 'react';
import { Breadcrumbs, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import useRouteNavigate from '../../hooks/useRouteNavigate';

interface Crumb {
  name: string;
  path?: string;
}

interface Props {
  crumbs: Crumb[];
}

const Breadcrumb = (props: Props): ReactElement => {
  const { crumbs } = props;
  const navigate = useRouteNavigate();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ color: grey[800], backgroundColor: grey[100], py: '10px', pl: { xs: '40px', sm: '40px', md: '48px' } }}
    >
      {crumbs.map(({ name, path }) => (
        <Button
          variant="text"
          key={name}
          onClick={() => path && navigate(path)}
          sx={{ fontWeight: 'bold', color: grey[800] }}
        >
          {name}
        </Button>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
