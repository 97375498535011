import React, { FC } from 'react';
import { Box, keyframes, Typography } from '@mui/material';
import { cyan } from '@mui/material/colors';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

interface Props {
  name: string;
  price: string;
  services: string[];
  darkTheme: boolean;
}

const Package: FC<Props> = ({ name, price, services, darkTheme }) => (
  <Box
    sx={{
      p: 3,
      height: '100%',
      background: darkTheme ? cyan[800] : cyan[300],
      color: darkTheme ? 'white' : '',
      boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      animation: `500ms ${fadeInAnimation}`,
    }}
  >
    <Typography variant="h5" sx={{ fontWeight: 'bold', color: darkTheme ? 'white' : '' }}>
      {name}
    </Typography>
    <Typography variant="h6" sx={{ pb: 3, color: darkTheme ? 'white' : '' }}>
      {price}
    </Typography>
    {services.map((service) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <CheckCircleRoundedIcon fontSize="small" />
        <Typography>{service}</Typography>
      </Box>
    ))}
  </Box>
);

export default Package;
