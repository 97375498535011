import React, { createContext, ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';

const initialState = {
  route: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRoute: (value: string) => {},
};
export const RoutingContext = createContext(initialState);

type ProviderProps = { children: ReactElement | ReactElement[] };

const RoutingProvider = ({ children }: ProviderProps): ReactElement => {
  const { pathname } = useLocation();
  const [route, setRoute] = useState(pathname);

  return (
    <RoutingContext.Provider
      value={{
        route,
        setRoute,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
};

export default RoutingProvider;
