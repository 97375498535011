// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const head = document.getElementsByTagName('head')[0];
  const { insertBefore } = head;
  head.insertBefore = (newElement, referenceElement) => {
    if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
      return newElement;
    }
    insertBefore.call(head, newElement, referenceElement);

    return newElement;
  };
};
