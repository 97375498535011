import React, { FC, ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import Container from 'components/common/Container';
import ServiceCard from 'components/common/ServiceCard';
import { services } from '../../data/services.json';

interface Props {
  images: string[];
}

const Services: FC<Props> = ({ images }): ReactElement => {
  const getImage = (id: string) => {
    switch (id) {
      case 'kardiologija':
        return images[0];
      case 'pulmologija':
        return images[1];
      case 'ultrazvucna-dijagnostika':
        return images[2];
      case 'terapija-bola':
        return images[3];
      case 'hepatologija':
        return images[4];
      case 'infektologija':
        return images[5];
      case 'onkohirurgija':
        return images[6];
      case 'neurologija':
        return images[7];
      case 'hematologija':
        return images[8];
      case 'ortopedija':
        return images[9];
      case 'endokrinologija':
        return images[10];
      case 'infuziona-terapija':
        return images[11];
      default:
        return images[0];
    }
  };
  return (
    <Container padding="60px 1vw 30px">
      <Typography variant="h4" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
        Usluge
      </Typography>
      <Grid container>
        {services.map(({ id, serviceName, description }) => (
          <Grid key={id} item xs={12} sm={6} md={4}>
            <ServiceCard id={id} serviceName={serviceName} description={description} image={getImage(id)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
