import React, { ReactElement } from 'react';
import ContactUsForm from 'components/ContactUsForm/contactUsForm';
import Map from 'components/common/Map';
import { Box, Grid } from '@mui/material';

const Contact = (): ReactElement => {
  return (
    <Box sx={{ p: '60px 0 0' }}>
      <Grid container direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ height: { xs: 500, sm: 500, md: '100%' } }}>
            <Map />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: '4vw' }}>
          <ContactUsForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
