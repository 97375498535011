import React, { ReactElement } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import useRouteNavigate from 'hooks/useRouteNavigate';
import { cyan, grey } from '@mui/material/colors';

interface Props {
  id: string;
  serviceName: string;
  description: string;
  image: string;
}

const ServiceCard = (props: Props): ReactElement => {
  const { id, serviceName, description, image } = props;
  const navigate = useRouteNavigate();

  return (
    <Card sx={{ m: 1, textAlign: 'center', background: grey[50] }}>
      <CardMedia component="img" height="200" image={image} alt="cardio" />
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {serviceName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              height: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              lineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button sx={{ width: '100%', fontWeight: 'bold', color: cyan[600] }} onClick={() => navigate(`/usluge/${id}`)}>
          Saznaj više
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServiceCard;
