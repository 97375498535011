import React, { ReactElement, useState } from 'react';
import { Box, Grid, Stack, Typography, IconButton, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import Container from 'components/common/Container';
import Table from 'components/common/Table';
import Package from 'components/common/Package';
import packages from '../../data/packages.json';
import { prices } from '../../data/prices.json';

const Prices = (): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Container>
      <Typography variant="h4" sx={{ textAlign: 'center', py: 4, fontWeight: 'bold' }}>
        Cenovnik
      </Typography>
      <Box
        sx={{
          background: grey[100],
          p: 3,
          mb: 6,
          boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          {packages.map(({ name, price, services, theme }) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Package name={name} price={price} services={services} darkTheme={theme === 'dark'} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Stack spacing={2} alignItems="center" sx={{ px: 3 }}>
        <TextField
          id="standard-bare"
          variant="outlined"
          placeholder="Pretraži..."
          sx={{ marginBottom: '20px', width: '100%', maxWidth: '1200px' }}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
        {prices.map((price) => (
          <Table key={price.serviceName} searchTerm={searchTerm} data={price} />
        ))}
      </Stack>
    </Container>
  );
};

export default Prices;
